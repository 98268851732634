<template>
  <tip-form
    action="create"
    :loading="loading"
    :updating="creating"
    :error-messages="errorMessages"
    :handle-submit="handleFormSubmit"
    :handle-discard="handleDiscard"
  />
</template>

<script>
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { computed, ref, getCurrentInstance } from '@vue/composition-api'

import TipForm from './tip-form/TipForm.vue'

export default {
  components: {
    TipForm,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const errorMessages = ref([])

    const loading = ref(false)
    const creating = computed(() => vm.$store.getters['tips/creating'])

    const handleFormSubmit = formData => {
      vm.$store
        .dispatch('tips/create', formData)
        .then(res => {
          if (res?.status === 200 && res?.data?.data?.tip) {
            const tipId = res.data.data.tip.id

            vm.$router.replace({
              name: 'tip-view',
              params: {
                id: tipId,
              },
            })
          }
        })
        .catch(err => {
          if (err?.data?.errors) {
            errorMessages.value = err.data.errors
          }
        })
    }

    const handleDiscard = () => vm.$router.replace({ name: 'tip-list' })

    return {
      loading,
      creating,

      errorMessages,

      handleFormSubmit,
      handleDiscard,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
